<template>

    <v-sheet class="mx-auto mt-6 pa-0" elevation="2">
      <v-col cols="12">
        <div class="col-12">
          <span class="subtitle-1 pr-2 black--text">Selecione as tags para visualizar os Tipos de Solicitação</span>
        </div>
        <div class="chips-container">
          <v-chip
            class="ma-2"
            style="padding: 2%"
            v-for="(chip, index) in tag_list.data"
            :key="index"
            :class="{
              'chip-selected': selectedChips.includes(chip),
              'primary': selectedChips.includes(chip)
            }"
            @click="toggleChip(chip)"
          >
            <v-icon>{{chip.icon}}</v-icon>
            {{chip.name}}
          </v-chip>
        </div>
      </v-col>
    </v-sheet>
</template>
<script>
import { mapState, mapActions } from "vuex";
export default {
  data() {
    return {
      selectedChips: [],
      loading: true
    };
  },
  computed: {
    ...mapState("tag", ["tag_list"]),
    ...mapState("solicitationType", ["list_solicitation_type_tag"]),
  },
  methods: {
    ...mapActions("tag", ["loadTag"]),
    ...mapActions("solicitationType", ["loadSolicitationTags"]),
    async toggleChip(chip) {
      if (this.selectedChips.includes(chip)) {
        this.selectedChips = this.selectedChips.filter((selectedChip) => selectedChip !== chip);
      } else {
        this.selectedChips.push(chip);
      }
      await this.loadSolicitationTags(this.selectedChips).catch(error => {
        this.$swal("Oops...", error.message, "error");
      });
    },
  },
  async mounted() {
    this.$store.commit('solicitationType/SET_LIST_SOLICITATION_TYPE_LIST_TAG', []);
    await this.loadTag().catch(error => {
      this.$swal("Oops...", error.message, "error");
    });
    this.loading = false;
  }
};
</script>
<style scoped>
.chips-container {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
}

.chips-container v-chip {
  margin-right: 10px;
  margin-bottom: 10px;
}

.chip-selected {
  background-color: #4caf50;
  color: #ffffff;
}

.primary {
  border: 2px solid #1976d2;
  color: #1976d2;
}
</style>