<template>
  <v-container class="mt-0 pt-0">
    <v-row>
      <v-col cols="12" class="pt-0">
        <v-card outlined>
          <v-card-title>
            <h3>
              <v-icon class="mr-3" color="primary">mdi-folder-pound</v-icon
              >Vamos iniciar uma nova solicitação
            </h3>
          </v-card-title>
          <v-card-text>
            <SolicitationTagComponent />
          </v-card-text>
          <v-spacer></v-spacer>
          <v-card-text>
            <SolicitationTypeComponent />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>


</template>

<script>
import SolicitationTagComponent from "../../components/solicitation/SolicitationTagComponent";
import SolicitationTypeComponent from "@/components/solicitation/SolicitationTypeComponent";
export default {
  components: {
    SolicitationTagComponent,
    SolicitationTypeComponent
  },
  data() {
    return {
      text: '',
      selectedChips: [],
    };
  },

  methods: {
    toggleChip(chip) {
      if (this.selectedChips.includes(chip)) {
        this.selectedChips = this.selectedChips.filter((selectedChip) => selectedChip !== chip);
      } else {
        this.selectedChips.push(chip);
      }
    },
  },
};
</script>